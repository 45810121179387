.angular-mapboxgl-compare {
  position: relative;
}
.angular-mapboxgl-compare .compare-map {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}
.angular-mapboxgl-map {
  position: relative;
}
.angular-mapboxgl-map .angular-mapboxgl-map-loader .spinner {
  display: none;
  width: 40px;
  height: 40px;
  top: calc(50% - (40px / 2));
  left: calc(50% - (40px / 2));
  position: relative;
}
.angular-mapboxgl-map .angular-mapboxgl-map-loader .spinner .double-bounce {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #333333;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}
.angular-mapboxgl-map .angular-mapboxgl-map-loader .spinner .double-bounce.delayed {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
.angular-mapboxgl-map .angular-mapboxgl-map-loader.angular-mapboxgl-map-loading {
  position: absolute;
  background-color: #999999;
  width: 100%;
  height: 100%;
  opacity: 0.75;
  z-index: 9900;
}
.angular-mapboxgl-map .angular-mapboxgl-map-loader.angular-mapboxgl-map-loading .spinner {
  display: block;
}
.angular-mapboxgl-map .directions-icon-depart,
.angular-mapboxgl-map .directions-icon-arrive {
  background-position: 50% 50%;
  width: 100%;
  height: 100%;
}
@-webkit-keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}
@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}
